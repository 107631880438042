<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card :header="$t('Release')" class="mt-4">
           <table class="table table-bordered">
            <tr>
              <td rowspan="9" class="text-center" width="300">
                <img 
                  class="mb-3"
                  v-bind:src="'https://assets.the-source.eu/frontcover/'+release.ProductDetails.ReportingCode+'/600?refresh=' + new Date().getTime()" width="100%" 
                />
                <a class="mb-1" 
                  v-if="$root.$data.me.access.ACE"
                  :href="ceUrl"
                >
                  View in Content-Editor
                </a>
                <p v-html="deliveryStatusFormatter(release)"></p>

                <template v-if="release.Security.ChangeArtwork">
                  <p><strong>{{ $t('Change artwork') }}:</strong></p>

                  <blob-upload v-on:input="artworkReplacement = $event" class="mb-4" />

                  <template v-if="artworkReplacement && artworkReplacementStatus == null">
                    <b-progress v-if="artworkReplacement.status == 'uploading'" :value="artworkReplacement.uploadPercentage" :max="100" show-progress />

                    <template v-if="artworkReplacement.status == 'uploadError'">
                      {{ $t('An error occurred while uploading, please try again.') }}
                    </template>
                    
                    <template v-if="artworkReplacement.status == 'uploadComplete'">
                      <template v-if="artworkReplacementValid == true">
                        <b-img fluid :src="artworkReplacementSrc" />

                        <b-button class="mt-3" @click.prevent v-b-modal.confirm-artwork-modal>{{ $t('Confirm artwork') }}</b-button>
                      </template>

                      <template v-if="artworkReplacementValid != true">
                        {{ $t('Problems found with the uploaded file. Please try again.') }}
                        <b-alert v-for="(msg, index) in artworkReplacementValid" :key="index" variant="danger" class="mt-4" show>
                          {{ msg }}
                        </b-alert>
                      </template>
                    </template>
                  </template>

                  <b-modal id="confirm-artwork-modal" :title="$t('Confirm artwork')" :ok-title="$t('Confirm')" :cancel-title="$t('Cancel')" 
                            :ok-disabled="artworkReplacementReason.length < 5" @ok="replaceArtwork">
                    <page-snippet name="artwork-replace" />
                    <b-form-textarea
                      id="textarea"
                      v-model="artworkReplacementReason"
                      :placeholder="$t('Enter reason...')"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-modal>

                  <template v-if="artworkReplacement && artworkReplacementStatus == 'progress'"> 
                    <b-spinner label="Spinning"></b-spinner>
                  </template>

                  <template v-if="artworkReplacement && artworkReplacementStatus == 'success'"> 
                    {{ $t('Artwork change has been requested. It can take a moment before this will be visible.') }}
                  </template>

                  <template v-if="artworkReplacement && artworkReplacementStatus == 'error'"> 
                    {{ $t('An error occurred while uploading, please try again.') }}
                  </template>
                </template>
              </td>
              <th>{{ $t('Reporting Code') }}</th><td>{{ release.ProductDetails.ReportingCode }}</td>
              <th>{{ $t('Release Date') }}</th><td>{{ release.ReleaseDate|dateFormat }}</td>
            </tr>
            <tr>
              <th>{{ $t('Catalog Number') }}</th><td>{{ release.CatalogNr }}</td>
              <th>{{ $t('Original Release Date') }}</th><td>{{ release.OriginalReleaseDate|dateFormat }}</td>
            </tr>
            <tr>
              <th>{{ $t('Barcode') }}</th><td>{{ release.ProductDetails.UPCorEAN }}</td>
              <th>{{ $t('Label Website') }}</th><td>{{ release.LabelURL }}</td>
            </tr>
            <tr>
              <th>{{ $t('Attribution') }}</th><td>{{ release.ProductDetails.Attribution }}</td>
              <th>{{ $t('Artist Website') }}</th><td>{{ release.ArtistURL }}</td>
            </tr>
            <tr>
              <th>{{ $t('Title') }}</th><td>{{ release.ProductDetails.Title }}</td>
              <th>{{ $t('TermSet Reference') }}</th><td>{{ release.ProductDetails.TermSet.Reference }}</td>
            </tr>
            <tr>
              <th>{{ $t('Version') }}</th><td>{{ release.ProductDetails.VersionTitle }}</td>
              <th>{{ $t('Org. Releaseformat') }}</th><td>{{ release.OriginalReleaseFormat }}</td>
            </tr>
            <tr>
              <th>{{ $t('C-Line') }}</th><td>{{ release.CLine }}</td>
              <th>{{ $t('Track count') }}</th><td>{{ release.TotalTrackCount }}</td>
            </tr>
            <tr>
              <th>{{ $t('P-Line') }}</th><td>{{ release.PLine }}</td>
              <th>{{ $t('Release Status') }}</th><td v-html="deliveryStatusFormatter(release)"></td>
            </tr>
            <tr>
              <th>{{ $t('Language') }}</th><td>{{ release.ProductDetails.TitleLanguage.Description }}</td>
              <th>{{ $t('Label') }}</th><td>{{ release.Label }}</td>
            </tr>
          </table>
        </b-card>

        <b-card header="Tracks" class="mt-4">
          <album-track-list :filter="{ AlbumID: release.AlbumID }">
          </album-track-list>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6" class="mt-4">
        <b-card header="Contributors">
          <contributor-list holder="Album" :holderId="release.AlbumID" />
        </b-card>
      </b-col>

      <b-col cols="6" class="mt-4">
        <b-card header="Tags">
          <tag-list holder="Album" :holderId="release.AlbumID" />
        </b-card>
      </b-col>

      <b-col cols="6" class="mt-4">
        <b-card header="Attributes">
          <attribute-list holder="Album" :productDetailsId="release.ProductDetailsID" />
        </b-card>
      </b-col>

      <b-col cols="6" class="mt-4">
        <b-card :header="$t('Release schedule')"> 
          <b-table-simple>
            <thead>
            <tr>
              <th>{{ $t('Outlet') }}</th>
              <th>{{ $t('Deliveries') }}</th>
            </tr>
            </thead>
            <tr v-for="(outlet, index) in outlets" :key="index">
              <td width="225" class="text-center outlet-logo">
                <img v-if="outlet.logoUrl" :src="outlet.logoUrl" :alt="outlet.name" :title="outlet.name" />
                <span v-if="!outlet.logoUrl">{{ outlet.name }}</span>
              </td>
              <td>
                <ul>
                  <li v-for="(delivery, index) in outlet.deliveries" :key="index">
                    <template v-if="!delivery.CompletionDateTime">
                      {{ $t('Processing') }}
                    </template>
                    <template v-if="delivery.CompletionDateTime">
                      {{ delivery.CompletionDateTime|datetimeFormat }}
                    </template>
                    {{ delivery.Type }}
                  </li>
                  <li v-if="outlet.deliveries.length == 0 && outlet.deliverTo === true">
                    {{ $t('Pending') }}
                  </li>
                  <li v-if="outlet.delivered === true && outlet.deliverTo === false">
                    {{ $t('Take Down Pending') }}
                  </li>
                  <li v-if="outlet.deliveries.length == 0 && outlet.blocked === true">
                    {{ $t('Not Available') }}
                  </li>
                </ul>
              </td>
            </tr>
          </b-table-simple>

          <router-link 
            v-if="release.Security.PortalUpdate"
            :to="{ name: 'release-detail-outlets' }" tag="b-button" class="mr-2">{{ $t('Change Outlet preferences') }}</router-link>

          <template v-if="release.Security.PortalUpdate">
            <b-button @click.prevent v-b-modal.takedown-modal>{{ $t('Full take-down') }}</b-button>

            <b-modal id="takedown-modal" :title="$t('Take down this release')" :ok-title="$t('Confirm')" 
                  :cancel-title="$t('Cancel')" @ok="$emit('takedown', fullTakedownReason)"
                  :ok-disabled="fullTakedownReason.length < 5" 
                  >
              <div v-html="$root.$data.pageSnippets['full-take-down']" />
              <b-form-textarea
                  id="textarea"
                  v-model="fullTakedownReason"
                  :placeholder="$t('Enter reason...')"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
            </b-modal>
          </template>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import AlbumTrackList from '@/components/AlbumTrackList.vue'

  export default {
    name: 'ReleaseDetail',
    components: {
      AlbumTrackList,
      'tag-list': () => import('@/components/TagList.vue'),
      'contributor-list': () => import('@/components/ContributorList.vue'),
      'attribute-list': () => import('@/components/AttributeList.vue'),
      'blob-upload': () => import('@/components/BlobUpload.vue'),
      'page-snippet': () => import('@/components/PageSnippet'),
    },
    data () {
      return {
        outlets: null,
        artworkReplacement: null,
        artworkReplacementStatus: null,
        artworkReplacementSrc: null,
        artworkReplacementReason: '',
        fullTakedownReason: '',
      }
    },
    props: {
      release: Object,
    },
    computed: {
      artworkReplacementValid () {
        if (this.artworkReplacement && this.artworkReplacement.status == 'uploadComplete') {
          return this.$validators.artworkFile(this.artworkReplacement)
        }
        return false
      },
      deliveries () {
        if (!this.outlets) return []
        let out = []
        this.outlets.forEach(outlet => {
          outlet.deliveries.forEach(delivery => {
            out.push(delivery)
          })
        })
        return out
      },
      ceUrl () {
        return `${process.env.VUE_APP_CE_URL}Album/${this.release.AlbumID}/`
      },
    },
    watch: {
      artworkReplacement(file) {
        let reader = new FileReader();
        let self = this
        reader.onload = function(e) {
          self.artworkReplacementSrc = e.target.result;
        }
        reader.readAsDataURL(file.rawFile);
      }
    },
    methods: {
      replaceArtwork() {
        console.log("Replacing artwork")
        this.artworkReplacementStatus = 'progress'

        this.$http.put('albums/' + this.release.AlbumID + '/replace-artwork', 
        { 
          TemporaryBlobID: this.artworkReplacement.sha,
          Reason: this.artworkReplacementReason,
        }).then(() => {
          this.artworkReplacementStatus = 'success'
        })
        .catch(() => {
          this.artworkReplacementStatus = 'error'
        })
      },
      deliveryStatusFormatter(data) {
        const ds = data.ProductDetails.DeliveryStatus
        let desc = this.$root.$data.fieldDefs.Album.AlbumDeliveryStatus.Options.find(s => {return s.JsonValue == ds.StatusID})
        if (desc) {
          desc = desc.Value
        } else {
          desc = ds.Description
        }

        return desc 
      },
    },
    mounted () {
      this.$root.$data.crumbs = [
        {to: { name: 'release-search' }, text: this.$t('Albums')},
        {text: this.release.ProductDetails.Attribution + ' - ' + this.release.ProductDetails.Title}
      ]

      this.$http
        .get('albums/'+this.release.AlbumID+'/outlet-portals')
        .then(response => {
            this.outlets = response.data.filter(outlet => !['Facebook', 'Facebook_AudioLibrary', 'Future'].includes(outlet.name))
        }).catch(function () {
            this.outlets = []
        })
    }
  }
</script>

<style lang="scss">
  td.outlet-logo {
    padding: 10px;
    img {
      max-width: 125px;
      max-height: 75px;
    }
  }
</style>